.subtitle {
    font-family: 'M PLUS Rounded 1c', 'Inter', 'Segoe UI', 'Helvetica', sans-serif;
    font-size: 1.5rem;
    margin: 5px;
    font-weight: 700;
}

@media (max-width: 800px) {
    .box {
        margin-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 50px;
    }

}

@media (min-width: 801px) {
    .box {
        padding-left: 100px;
        padding-right: 100px;
        padding-bottom: 50px;
    }
}

.portfolio_img {
    max-width: 100%;
    width: 800px;
}



