#page-wrap {
  /* padding-bottom: 10px; */
  padding-top: 10px;
}

body {
  margin: 0;
  font-family: 'Inter', 'Segoe UI', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

* {
  box-sizing: border-box;
}

h1 {
  font-family: 'M PLUS Rounded 1c', 'Inter', 'Segoe UI', 'Helvetica', sans-serif;
  font-size: 4rem;
  font-weight: 800;
}

h2 {
  font-family: 'M PLUS Rounded 1c', 'Inter', 'Segoe UI', 'Helvetica', sans-serif;
  font-size: 2rem;
  font-weight: 800;
}

h3 {
  font-family: 'M PLUS Rounded 1c', 'Inter', 'Segoe UI', 'Helvetica', sans-serif;
  font-size: 1.5rem;
  margin: 5px;
  font-weight: 700;
}

p {
  font-family: 'Inter', 'Segoe UI', 'Helvetica', sans-serif;
  font-size: 1rem;
}

:before,
:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.buttons {
  margin-top: 30px;
  border-radius: 30px;
}

.blob-btn {
  z-index: 1;
  position: relative;
  padding: 15px 30px;
  margin-bottom: 0px;
  text-align: center;
  text-transform: uppercase;
  color: #E5735C;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  outline: none;
  border: none;
  transition: color 0.5s;
  cursor: pointer;
  border-radius: 30px;
  width: 320px;
}

.blob-btn:before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #E5735C;
  border-radius: 30px;
}

.blob-btn:after {
  content: "";
  z-index: -2;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 100%;
  height: 100%;
  transition: all 0.3s 0.2s;
  border-radius: 30px;
}

.blob-btn:hover {
  color: #fff;
  border-radius: 30px;
}

.blob-btn:hover:after {
  transition: all 0.3s;
  left: 0;
  top: 0;
  border-radius: 30px;
}

.blob-btn__inner {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: #ffff;
}

.blob-btn__blobs {
  position: relative;
  display: block;
  height: 100%;
  filter: url('#goo');
}

.blob-btn__blob {
  position: absolute;
  top: 2px;
  width: 25%;
  height: 100%;
  background: #E5735C;
  border-radius: 100%;
  transform: translate3d(0, 150%, 0) scale(1.7);
  transition: transform 0.45s;
}

@supports (filter: url('#goo')) {
  transform: translate3d(0, 150%, 0) scale(1.4);
}

.blob-btn__blob:nth-child(1) {
  left: 0%;
  transition-delay: 0s;
}

.blob-btn__blob:nth-child(2) {
  left: 30%;
  transition-delay: 0.08s;
}

.blob-btn__blob:nth-child(3) {
  left: 60%;
  transition-delay: 0.16s;
}

.blob-btn__blob:nth-child(4) {
  left: 90%;
  transition-delay: 0.24s;
}

.blob-btn:hover .blob-btn__blob {
  transform: translateZ(0) scale(1.7);
}

@supports (filter: url('#goo')) {
  transform: translateZ(0) scale(1.4);
}